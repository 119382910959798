import React, { useCallback } from "react";
import { useIntl } from "../hooks/useIntl";
import { graphql } from "gatsby";
import Loadable from "@loadable/component";

import Page from "../components/Page";
import { getTranslations } from "../helpers/translations";
import { getAssetURL } from "../helpers/assets";
import { useStaticLabels } from "../hooks/useStaticLabels";
import OurProducts, { OurProductProps } from "../components/OurProducts";
import SEO from "../components/SEO";
import { parseSEO } from "../helpers/seo";
import { ROUTES } from "../helpers/routes";
import { isPublished } from "../helpers/directus";

const LoadableProductOverview = Loadable(
  () => import("../components/ProductOverview")
);

const ProductOverviewPage = ({ data }: { data: any }) => {
  const intl = useIntl();
  const labels = useStaticLabels();

  const getRootTranslatedData = useCallback(() => {
    return (
      getTranslations(
        data,
        "directus.product_overview_page.translations",
        intl.locale
      ) ?? {}
    );
  }, [data]);

  const getOurProductsSectionData = useCallback((): OurProductProps => {
    const translatedData = getRootTranslatedData();

    return {
      isPublished: isPublished(translatedData?.each_product_status),
      image: {
        src: getAssetURL(translatedData?.our_products_image_1),
        alt: translatedData?.our_products_image_1_alt,
      },
      image2: {
        src: getAssetURL(translatedData?.our_products_image_2),
        alt: translatedData?.our_products_image_2_alt,
      },
      title: translatedData?.our_products_title,
      description: translatedData?.our_products_description,
      primaryAction: {
        label: labels?.shopNow,
        link: translatedData?.our_products_shop_now_link,
      },
      secondaryAction: {
        label: labels?.findNearMe,
        link: translatedData?.our_products_find_near_me_link,
      },
      optionalAction: {
        label: labels?.buyOnline,
        link: translatedData?.our_products_shop_now_link,
      },
    };
  }, [getRootTranslatedData, intl]);

  const getEachProductData = useCallback(() => {
    const translatedData = getRootTranslatedData();

    const { products = [] } = translatedData;
    return {
      productList: products
        ?.map(({ each_product_id }) => {
          const product = getTranslations(
            each_product_id,
            "translations",
            intl.locale
          );

          const productPage = getTranslations(
            product,
            "product_page.translations",
            intl.locale
          );

          const {
            product_name,
            status,
            variants = [],
          } = getTranslations(
            productPage?.product,
            "translations",
            intl.locale
          ) ?? {};

          const selectedVariants = isPublished(productPage?.variants_status)
            ? variants
            : [];

          const publishedVariants = selectedVariants
            ?.map(({ product_variant_id }) => {
              const productVariant = getTranslations(
                product_variant_id,
                "translations",
                intl.locale
              );
              return {
                ...productVariant,
                variant_slug: product_variant_id?.variant_slug,
              };
            })
            ?.filter(({ status }) => isPublished(status));

          return {
            status: product?.each_product_status,
            currentLocale: intl.locale,
            title: product?.each_product_title,
            description: product?.each_product_description,
            findText: product?.each_product_location,
            primaryButton: {
              label: labels?.productDetails,
            },
            secondaryButton: {
              label: labels?.findNearMe,
              visible: product?.each_product_find_near_me,
            },
            optionalButton: {
              label: labels?.buyOnline,
            },
            slides: publishedVariants.map((slide: any) => ({
              primaryImg: {
                src: getAssetURL(slide?.large_image),
                alt: slide?.large_image_alt,
              },
              secondaryImg: {
                src: getAssetURL(slide?.small_image),
                alt: slide?.small_image_alt,
              },
              subTitle: slide?.subtitle,
              primaryButtonLink: `${ROUTES.PRODUCT_DETAIL}/${product.product_page.product_page_slug}/${slide?.variant_slug}`,
              secondaryButtonLink: slide?.options
                ?.map(
                  ({
                    available_quantity_id,
                  }: {
                    available_quantity_id: any;
                  }) => {
                    const translatedOptions =
                      getTranslations(
                        available_quantity_id,
                        "translations",
                        intl.locale
                      ) ?? {};
                    return {
                      status: translatedOptions?.status,
                      link: translatedOptions?.find_near_me_link,
                    };
                  }
                )
                ?.find(
                  (options: any) =>
                    isPublished(options?.status) && options?.link
                )?.link,
              optionalButtonLink: slide?.options
              ?.map(
                ({
                  available_quantity_id,
                }: {
                  available_quantity_id: any;
                }) => {
                  const translatedOptions =
                    getTranslations(
                      available_quantity_id,
                      "translations",
                      intl.locale
                    ) ?? {};
                  return {
                    status: translatedOptions?.status,
                    link: translatedOptions?.shop_now_link,
                  };
                }
              )
              ?.find(
                (options: any) =>
                  isPublished(options?.status) && options?.link
              )?.link,
            })),
          };
        })
        ?.filter(({ status }: { status: string }) => isPublished(status)),
    };
  }, [getRootTranslatedData, intl]);

  return (
    <Page
      headerBackgroundColor={"#012102"}
      helmet={
        <SEO
          {...parseSEO(getRootTranslatedData(), intl?.locale)}
        />
      }
    >
      <OurProducts {...getOurProductsSectionData()} />
      {getEachProductData()?.productList?.map((product, index) => (
        <LoadableProductOverview key={index} {...product} />
      ))}
    </Page>
  );
};

export default ProductOverviewPage;

export const query = graphql`
  query ($locale: String) {
    directus {
      product_overview_page {
        translations(filter: { languages_code: { code: { _eq: $locale } } }) {
          seo_information {
            ...SEO
          }
          languages_code {
            code
            name
          }
          status
          our_products_title
          our_products_description
          our_products_shop_now_link
          our_products_find_near_me_link
          our_products_image_1 {
            id
            filename_disk
          }
          our_products_image_1_alt
          our_products_image_2 {
            id
            filename_disk
          }
          our_products_image_2_alt
          each_product_status
          products {
            each_product_id {
              translations(
                filter: { languages_code: { code: { _eq: $locale } } }
              ) {
                languages_code {
                  code
                  name
                }
                each_product_status
                each_product_title
                each_product_description
                each_product_location
                each_product_find_near_me
                product_page {
                  product_page_slug
                  translations(
                    filter: { languages_code: { code: { _eq: $locale } } }
                  ) {
                    languages_code {
                      code
                      name
                    }
                    status
                    variants_status
                    product {
                      translations(
                        filter: { languages_code: { code: { _eq: $locale } } }
                      ) {
                        languages_code {
                          code
                          name
                        }
                        status
                        product_name
                        variants {
                          product_variant_id {
                            variant_slug
                            translations(
                              filter: {
                                languages_code: { code: { _eq: $locale } }
                              }
                            ) {
                              languages_code {
                                code
                                name
                              }
                              status
                              title
                              subtitle
                              large_image_alt
                              large_image {
                                id
                                filename_disk
                              }
                              small_image_alt
                              small_image {
                                id
                                filename_disk
                              }
                              options {
                                available_quantity_id {
                                  translations(
                                    filter: {
                                      languages_code: { code: { _eq: $locale } }
                                    }
                                  ) {
                                    languages_code {
                                      code
                                      name
                                    }
                                    status
                                    quantity
                                    image {
                                      id
                                      filename_disk
                                    }
                                    image_alt
                                    shop_now_link
                                    find_near_me_link
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
