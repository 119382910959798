import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { rem, vwDesktop, vwMobile, toREM } from "../../helpers/styles";
import { MEDIA_DESKTOP } from "../../helpers/theme";
import ButtonLink, { ButtonType } from "../shared/ButtonLink";
import { Image } from "../../types";
import { useIntl } from "../../hooks/useIntl";

export type OurProductProps = {
  isPublished: boolean;
  image: Image;
  image2: Image;
  title: string;
  description: string;
  primaryAction: { label: string; link: string };
  secondaryAction: { label: string; link: string };
  optionalAction: { label: string; link: string };
};

const getizeRatio = (text: string) => {
  if (text?.length > 10) {
    return 0.75;
  }
  return 1;
};

export default function OurProducts({
  isPublished,
  image,
  image2,
  title,
  description,
  primaryAction,
  secondaryAction,
  optionalAction,
}: OurProductProps): JSX.Element | null {
  const [frontImg, setFrontImg] = useState(2); // 1 and 2
  const internalLocale = useIntl();
  const [locale, setLocale] = useState("");

  useEffect(() => {
    setLocale(internalLocale.locale);
  }, []);

  if (!isPublished) return null;

  useEffect(() => {
    const int = setInterval(() => {
      setFrontImg((prev) => (prev === 1 ? 2 : 1));
    }, 5 * 1000);
    return () => {
      clearInterval(int);
    };
  }, []);

  return (
    <>
      <Container>
        <Previews>
          <Photos>
            <img
              className={frontImg === 2 ? "rotated" : ""}
              src={image?.src}
              alt={image?.alt}
            />
            <img
              className={frontImg === 1 ? "rotated" : ""}
              src={image2?.src}
              alt={image2?.alt}
            />
          </Photos>
        </Previews>
        <SellingText>
          <Title
            ratio={getizeRatio(title)}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <Description dangerouslySetInnerHTML={{ __html: description }} />
          <Actions>
            {(primaryAction.link || optionalAction.link) && <Action>
              <ButtonLink
                link={primaryAction.link}
                label={primaryAction.label}
                type={ButtonType.PRIMARY_LIGHT}
              />
            </Action>}

            {secondaryAction.link && <Action>
              <ButtonLink
                link={secondaryAction.link}
                label={secondaryAction.label}
                type={ButtonType.SECONDARY_LIGHT}
              />
            </Action>}
          </Actions>
        </SellingText>
      </Container>
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-flow: column;

  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${rem(18)};
  color: white;
  overflow: hidden;

  ${MEDIA_DESKTOP} {
    flex-flow: row-reverse;
    align-items: stretch;
    gap: 0;
    height: ${vwDesktop(764)};
  }
`;

const Column = styled.div<{ color?: string }>`
  background: ${(p) => p.color ?? "#012102"};
  width: 100%;
  padding: ${vwMobile(24)};

  ${MEDIA_DESKTOP} {
    width: 50%;
    padding: 0;
  }
`;

const Previews = styled(Column)`
  display: flex;
  justify-content: center;

  ${MEDIA_DESKTOP} {
    padding-top: ${vwDesktop(33)};
    padding-bottom: ${vwDesktop(76)};
    padding-right: ${(props) => (props.theme.isRTL ? 0 : `${vwDesktop(88)}`)};
    padding-left: ${(props) => (props.theme.isRTL ? `${vwDesktop(88)}` : 0)};
  }
`;

const Photos = styled.div`
  display: grid; // to stack them
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  align-items: center;
  justify-items: center;

  width: ${vwMobile(327)};
  height: ${vwMobile(327)};

  ${MEDIA_DESKTOP} {
    width: ${vwDesktop(532)};
    height: ${vwDesktop(532)};
  }

  img {
    grid-column: 1;
    grid-row: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;

    transition: all 0.3s ease-out;
    z-index: 2;

    &.rotated {
      z-index: 1;
      transform: rotate(-7deg);
      ${MEDIA_DESKTOP} {
        left: ${vwDesktop(-23)};
        top: ${vwDesktop(14)};
      }
    }
  }
`;

const SellingText = styled(Column)`
  padding-bottom: ${vwMobile(40)};

  ${MEDIA_DESKTOP} {
    width: 50%;
    padding-top: ${vwDesktop(67)};
    padding-bottom: ${vwDesktop(40)};
    padding-left: ${(props) => (props.theme.isRTL ? 0 : `${vwDesktop(205)}`)};
    padding-right: ${(props) => (props.theme.isRTL ? `${vwDesktop(205)}` : 0)};
  }
`;

const Title = styled.h1<{ ratio: number }>`
  font-size: ${rem(48)};
  letter-spacing: 0.03em;
  line-height: ${rem(52)};
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: ${rem(19)};

  ${MEDIA_DESKTOP} {
    font-size: ${(props) => props.ratio * toREM(100)}rem;
    line-height: ${rem(100)};
    margin-bottom: ${rem(28)};
  }
`;

const Description = styled.p`
  font-size: ${rem(18)};
  font-weight: 600;
  line-height: ${rem(28)};
  letter-spacing: 0;
  margin-bottom: ${vwMobile(45)};

  ${MEDIA_DESKTOP} {
    margin-bottom: ${vwDesktop(45)};
    width: 79%;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${vwMobile(16)};

  ${MEDIA_DESKTOP} {
    gap: ${vwDesktop(20)};
    max-width: ${vwDesktop(380)};
    flex-direction: row;

    > * {
      flex-grow: 1;
    }
  }
`;

const Action = styled.div`
  flex: 1;
  min-width: fit-content;
`;
